import React, { useContext, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useLazyQuery } from '@apollo/react-hooks';
// services
import { getCookie } from '../services/cookies';
// queries
import { RECOMMENDED_PRODUCTS } from '../queries/user';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Image from '@components/image/image';
import AddToBasket from '../components/add-to-basket';
import Loader from '../components/loader';
// styles
import styles from './compare-personalised-products.module.scss';
import { formatPrice } from '../services/format';
import { COOKIES } from '@constants/cookies';
// hooks
import { useProductPlansLazyQuery } from '../hooks/useProductPlans';
import { GlobalContext } from '@src/store/global-state';

const ComparePersonalisedProducts = () => {
  const content = useStaticQuery(graphql`
    query ComparePersonalisedProducts {
      directusComparePersonalisedProduct {
        page_title
        page_description
        hero_title
        hero_description
        hero_image {
          title
          filename_disk
        }
        product_1_title
        product_1_bullets {
          bullet
        }
        product_1_price
        product_1_button_label
        product_2_title
        product_2_bullets {
          bullet
        }
        product_2_price
        product_2_button_label
      }
    }
  `);

  const { isLoggedIn } = useContext(GlobalContext);
  const { directusComparePersonalisedProduct } = content;

  const [
    getRecommendedProducts,
    { data: dataProducts, loading: loadingProducts, error: errorProducts },
  ] = useLazyQuery(RECOMMENDED_PRODUCTS, {
    fetchPolicy: 'network-only',
  });

  const [
    getPlansEssential,
    { data: dataPlansEssential, loading: loadingPlansEssential, error: errorPlansEssential },
  ] = useProductPlansLazyQuery();

  const [
    getPlansPersonalised,
    {
      data: dataPlansPersonalised,
      loading: loadingPlansPersonalised,
      error: errorPlansPersonalised,
    },
  ] = useProductPlansLazyQuery();

  const personalisedProductRecommendation = dataProducts?.health_recommendedProducts.find(
    (product: any) => product.id.startsWith('sn')
  );

  const essentialOneRecommendation = dataProducts?.health_recommendedProducts.find((product: any) =>
    product.id.startsWith('essential-one')
  );

  useEffect(() => {
    if (isLoggedIn) getRecommendedProducts();
  }, [isLoggedIn]);

  useEffect(() => {
    essentialOneRecommendation &&
      getPlansEssential({
        variables: {
          productId: essentialOneRecommendation && essentialOneRecommendation.id,
        },
      });
  }, essentialOneRecommendation);

  useEffect(() => {
    personalisedProductRecommendation &&
      getPlansPersonalised({
        variables: {
          productId: personalisedProductRecommendation && personalisedProductRecommendation.id,
        },
      });
  }, personalisedProductRecommendation);

  const essentialOnePrice = formatPrice(
    dataPlansEssential?.product_plans[0].rrpPrice,
    dataPlansEssential?.product_plans[0].currency
  );
  const personalisedPackPrice = formatPrice(
    dataPlansPersonalised?.product_plans[0].rrpPrice,
    dataPlansPersonalised?.product_plans[0].currency
  );

  if (loadingProducts || loadingPlansEssential || loadingPlansPersonalised) return <Loader />;

  return (
    <Layout>
      <SEO
        title={directusComparePersonalisedProduct.page_title}
        description={directusComparePersonalisedProduct.page_description}
      />

      <section className={styles.section}>
        <div className={styles.container}>
          <h1>{directusComparePersonalisedProduct.hero_title}</h1>
          <hr />
          <p>{directusComparePersonalisedProduct.hero_description}</p>
          <Image
            filename={directusComparePersonalisedProduct.hero_image.filename_disk}
            width={1488}
          />

          <div className={styles.productsWrapper}>
            <div className={styles.label}>Most popular</div>
            <div className={styles.row}>
              <div className={styles.col}>
                <h3>{directusComparePersonalisedProduct.product_1_title}</h3>
                <hr className={styles.hr} />
              </div>
              <div className={styles.col}>
                <h3>{directusComparePersonalisedProduct.product_2_title}</h3>
                <hr className={styles.hr} />
              </div>
            </div>
            <div className={styles.products}>
              {directusComparePersonalisedProduct.product_1_bullets.map(
                (bullet: any, index: number) => (
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <img src={'/images/icon-compare-check-circle.svg'} alt="" />
                      {bullet.bullet}
                    </div>
                    <div className={styles.col}>
                      <img src={'/images/icon-compare-check-circle.svg'} alt="" />
                      {directusComparePersonalisedProduct.product_2_bullets[index].bullet}
                    </div>
                  </div>
                )
              )}
            </div>
            <div className={styles.row}>
              <div className={styles.col}>
                {!dataPlansPersonalised && personalisedPackPrice && (
                  <h4>{personalisedPackPrice}</h4>
                )}
                {dataPlansPersonalised ? (
                  <AddToBasket
                    plans={dataPlansPersonalised && dataPlansPersonalised.product_plans}
                  />
                ) : (
                  <Link className={styles.button} to={'/consultation'}>
                    {directusComparePersonalisedProduct.product_1_button_label}
                  </Link>
                )}
              </div>
              <div className={styles.col}>
                {!dataPlansEssential && essentialOnePrice && <h4>{essentialOnePrice}</h4>}
                {dataPlansEssential ? (
                  <AddToBasket plans={dataPlansEssential && dataPlansEssential.product_plans} />
                ) : (
                  <Link className={styles.button} to={'/consultation'}>
                    {directusComparePersonalisedProduct.product_1_button_label}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ComparePersonalisedProducts;
